import * as React from "react"
import useSiteMetadata from "../hooks/use-site-metadata"
import Layout from "../components/layout"
import Container from "../components/container"
import Hero from "../components/hero"
import AdvertiseForm from "../components/advertise-form"

const SubmitAStorePage = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <Layout
      title={"Advertise With Us"}
      description={
        "Want to advertise your store or website on Tokyo Chuko? Please fill in the form below and we will get back to you as soon as possible regarding potential opportunities."
      }
      noGap
      className={"bg-white"}
    >
      <Hero title={"Advertise"} titleColor={"text-gray-800"} />
      <div className={"flex-grow"}>
        <Container className={"py-10 justify-center"}>
          <div className={"flex flex-col gap-8"}>
            <div className={"prose"}>
              <p>
                Want to advertise your store or website on Tokyo Chuko? Please
                fill in the form below and we will get back to you as soon as
                possible regarding potential opportunities.
              </p>
            </div>

            <AdvertiseForm action={siteMetadata.advertiseFormAction} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default SubmitAStorePage
