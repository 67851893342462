import * as React from "react"
import Form, { FormProps } from "../form"
import classNames from "classnames"

interface AdvertiseFormProps extends Omit<FormProps, "children"> {}

export default function AdvertiseForm(props: AdvertiseFormProps) {
  return (
    <Form {...props}>
      {inputClasses => (
        <>
          <input
            name="name"
            type="text"
            placeholder="NAME"
            className={inputClasses}
            required
          />

          <input
            name="email"
            type="email"
            placeholder="EMAIL"
            className={inputClasses}
            required
          />

          <input
            name="company"
            type="text"
            placeholder="COMPANY"
            className={inputClasses}
          />

          <select
            className={classNames(inputClasses, "uppercase")}
            name={"typeOfAdvertisement"}
          >
            <option value={""}>Type of Advertisement</option>
            <option value={"store"}>Store</option>
            <option value={"website"}>Website</option>
          </select>

          <textarea
            name="additionalInformation"
            rows={7}
            className={inputClasses}
            placeholder="ADDITIONAL INFORMATION"
          />
        </>
      )}
    </Form>
  )
}
